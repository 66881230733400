html,
body {
  height: 100%;
  min-height: 100%;
  overflow: hidden;
  font-family: "Source Sans Pro", sans-serif;
}
.o_hidden {
  overflow: hidden;
}
.content_details_wrap {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  margin: 100px 0px;
  text-align: center;
  height: 100vh;
}
.content_details_wrap.permission {
  margin: 50px 0;
  overflow: auto;
}
.content_details_wrap.deny_permission {
  margin-top: 50px;
}
.content_details {
  z-index: -9;
  position: relative;
  max-width: 260px;
  text-align: center;
  margin: 0 auto;
}
.main_title {
  font-size: 28px;
  line-height: 34px;
  color: #364347;
}
.main_title .name {
  color: #3eb5ac;
}
.address_wrap {
  margin-top: 20px;
  font-size: 17px;
  line-height: normal;
  color: #3d4c51;
  font-weight: 600;
}
.address_wrap .address.w_normal{
  font-weight: normal;
}
.address_wrap .sub_title {
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: #c1d3da;
  text-transform: uppercase;
}
.press_drop_wrap {
  position: relative;
  top: 80px;
}
.press_drop_wrap .text_wrap {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  position: relative;
  width: 100%;
  z-index: 99;
}
.press_drop_wrap .text_wrap.disabled {
  opacity: 0.5;
}
.press_drop_wrap .text_wrap .text {
  width: 50px;
  text-align: center;
  display: inline-flex;
  font-size: 20px;
  line-height: 22px;
  position: relative;
  top: -1px;
  left: -3px;
  z-index: 1;
  pointer-events: none;
}
.circle {
  width: 120px;
  position: absolute;
  top: 50%;
  left: 50%;
  transition: all 3s linear;
}
.circle.scale_circle {
  width: 2000px;
}
.thanks_wrap_main {
  height: 100vh;
}
.thanks_wrap {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 17px;
  font-weight: 400;
  width: 100%;
  padding: 0px 10px;
}
.thanks_text {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  color: #364347;
  margin-top: 12px;
  line-height: normal;
}
.captured_info {
  max-width: 290px;
  margin: 0 auto;
}
.location_wrap {
  margin-top: 160px;
}
.location_wrap .location_txt {
  font-size: 20px;
  color: #364347;
  line-height: 24px;
  font-weight: 400;
  max-width: 345px;
  margin: 0 auto;
}
.location_wrap .location_txt .thanks_txt {
  display: block;
  margin-top: 10px;
  font-weight: 600;
}
.deny_permission .content_details {
  max-width: 310px;
}
.deny_permission .location_wrap {
  margin-top: 20px;
}
.deny_permission .location_txt {
  max-width: 300px;
  margin: 0 auto;
}

.need-permission-title {
  min-width: 320px;
  font-size: 22px;
  font-weight: bold;
  color: #364347;
}

.permission-steps {
  padding-top: 20px;
  font-size: 20px;
  font-weight: normal;
  line-height: 1.4;
  color: #364347;
  text-align: left;
}

canvas {
  display: block;
  position: absolute;
  z-index: 999;
  pointer-events: none;
  width: 100%;
  height: 100vh;
}
 
/* START LOADER */
.show {
  display: block !important;
}

.hide {
  display: none !important;
}

.loader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 103;
  background: rgba(0, 0, 0, 0.4);
}

.loaderbg {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 103;
  background: rgba(0, 0, 0, 0.4);
}

.loaderbg .loader_content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.loaderbg.loaderbgUS {
  background: none;
}

.loaderbg .loader_content .loaderText {
  color: #fff;
  animation: blink 2s linear infinite;
}

.loaderText {
  color: #fff;
  animation: blink 2s linear infinite;
}

.loaderbg .loader_content .loaderimg {
  width: 70px;
  height: 70px;
  display: inline-block;
}

.loaderbg .loader_content .loaderimg img {
  width: 100%;
  opacity: 0.5;
}

* {
  -webkit-overflow-scrolling: touch;
}

/* END LOADER */
